import React from "react";
import { useSpring, animated, config } from "react-spring";

// const config = { mass: 10, tension: 1000, friction: 200 };

export default function AnimatedLine(props) {

  const lineAnimation = useSpring({
    borderBottom: `5px solid ${props.color}`,
    opacity: props.toggle ? 1 : 0,
    width: props.toggle ? '50%' : '0%',
    from: { opacity: 0, width: "0%" },
    config: config.wobbly,
    delay: props.delay,
  })

  return (
    <animated.div className={props.styleName} style={lineAnimation}>
      {props.children}
    </animated.div>
  );
}
