import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useSpring, animated, config } from "react-spring"
import logo from "../../images/logo.png"

const HomeHero = () => {
  const [distance, setDistance] = useState()
  const data = useStaticQuery(graphql`
    query Image {
      heroMZ: file(relativePath: { eq: "hero_mz_contrast.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      heroBB: file(relativePath: { eq: "hero_bb_contrast.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  useEffect(() => {
    typeof window !== `undefined` &&
      window.addEventListener("scroll", () => {
        setDistance(window.pageYOffset)
      })
    return () => {
      typeof window !== `undefined` &&
        window.removeEventListener("scroll", () => {
          setDistance(window.pageYOffset)
        })
    }
  }, [])

  const animationRight = useSpring({
    opacity: 1,
    transform: "translate3d(0, 0rem, 0)",
    from: { opacity: 0, transform: "translate3d(-15rem, 0, 0)" },
    config: config.slow,
    delay: 300,
  })

  const animationLeft = useSpring({
    opacity: 1,
    transform: "translate3d(0, 0rem, 0)",
    from: { opacity: 0, transform: "translate3d(15rem, 0, 0)" },
    config: config.slow,
    delay: 300,
  })

  const animationTop = useSpring({
    opacity: 1,
    transform: "translate3d(0, 0rem, 0)",
    from: { opacity: 0, transform: "translate3d(0, -5rem, 0)" },
    config: config.slow,
    delay: 300,
  })

  const animationBottom = useSpring({
    opacity: 1,
    transform: "translate3d(0, 0rem, 0)",
    from: { opacity: 0, transform: "translate3d(0, 5rem, 0)" },
    config: config.slow,
    delay: 300,
  })

  const scrollAnimation = useSpring({
    opacity:
      typeof window != `undefined` && distance > window.innerHeight / 3 ? 0 : 1,
    transform:
      typeof window != `undefined` && distance > window.innerHeight / 3
        ? "translate3d(0, -15rem, 0)"
        : "translate3d(0, 0rem, 0)",
    config: config.spring,
  })

  return (
    <div>
      <div className="flex justify-center h-screen items-end w-full">
        <div className="w-full">
          <animated.div style={scrollAnimation} className="mb-16 lg:mb-10">
            <div className="flex">
              <animated.div
                style={animationRight}
                className="lato w-1/2 text-2xl lg:text-5xl text-right text-gray-800"
              >
                Software
              </animated.div>

              <animated.div
                className="mx-3 flex items-center"
                style={animationTop}
              >
                <img
                  className="w-10 lg:w-16"
                  src={logo}
                  alt="Becker&&Zietz Logo"
                />
              </animated.div>
              <animated.div
                style={animationLeft}
                className="lato w-1/2 text-2xl lg:text-5xl text-gray-800"
              >
                Beratung
              </animated.div>
            </div>
            <div className="flex">
              <animated.div
                style={animationRight}
                className=" lato w-1/2 text-2xl lg:text-5xl text-right text-gray-800"
              >
                Becker
              </animated.div>
              <animated.div
                className="mx-3 flex items-center"
                style={animationBottom}
              >
                <img
                  className="w-10 lg:w-16"
                  src={logo}
                  alt="Becker&&Zietz Logo"
                />
              </animated.div>
              <animated.div
                style={animationLeft}
                className="lato w-1/2 text-2xl lg:text-5xl text-gray-800"
              >
                Zietz
              </animated.div>
            </div>
          </animated.div>

          <animated.div
            style={scrollAnimation}
            className="w-full pb-16 lg:pb-0"
          >
            <div className="flex w-full">
              <animated.div style={animationRight} className="w-1/2 lg:mx-5">
                <Img
                  className="w-full"
                  fluid={data.heroBB.childImageSharp.fluid}
                />
              </animated.div>
              <animated.div style={animationLeft} className="w-1/2 lg:mx-5">
                <Img
                  className="w-full"
                  fluid={data.heroMZ.childImageSharp.fluid}
                />
              </animated.div>
            </div>
            <animated.div
              style={animationBottom}
              className="pt-2 border-b-5 border-gray-900 lg:pt-0 lg:border-none"
            ></animated.div>
          </animated.div>
        </div>
      </div>
    </div>
  )
}

export default HomeHero
