import React, { useState, useEffect } from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import AnimatedLine from "../Layout/AnimatedLine"
import { Element } from "react-scroll"
import { isMobile } from "react-device-detect"
import { FiArrowRight } from "react-icons/fi"

const HomeCard = ({ project }) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (isMobile) {
      setVisible(true)
    }
  }, [])

  const onMouseEnter = () => {
    setVisible(true)
  }

  const onMouseLeave = () => {
    setVisible(false)
  }

  return (
    <Element name={project.title} className="element">
      <AniLink
        cover
        direction="up"
        to={project.slug}
        bg={project.color}
        duration={1.3}
      >
        <div
          className="lg:flex"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <div data-aos="zoom-in-up" className="lg:my-10 lg:w-2/3">
            <div className="lato text-2xl lg:text-4xl tracking-widest text-gray-800">
              {project.title}
            </div>
            <AnimatedLine color={project.color} toggle={visible} />
            <div className=" text-gray-800 mt-1 text-sm lg:text-base">
              {project.outline}
            </div>
          </div>
          <div
            data-aos="zoom-in-left"
            data-aos-offset={isMobile ? "0" : "200"}
            data-aos-delay="300"
            className="my-10 lg:w-1/3 flex justify-center items-center"
          >
            <FiArrowRight className="big-icon" color={project.color} />
          </div>
        </div>
      </AniLink>
      <div className="h-16"></div>
    </Element>
  )
}

export default HomeCard
