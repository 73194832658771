import React, { useState, useEffect } from "react"
import logo from "../../images/logo.png"
import AnimatedLine from "../Layout/AnimatedLine"

const AboutUs = ({ project }) => {
  const [toggleSingle, toggleToggleSingle] = useState(false)
  const [toggleDouble, toggleToggleDouble] = useState(false)

  useEffect(() => {
    typeof document !== `undefined` &&
      document.addEventListener("aos:in", ({ detail }) => {
        if (detail.id === "aboutus") {
          toggleToggleSingle(true)
        }
        if (detail.id === "aboutus2") {
          toggleToggleDouble(true)
        }
      })
    document.addEventListener("aos:out", ({ detail }) => {
      if (detail.id === "aboutus") {
        toggleToggleSingle(false)
      }
      if (detail.id === "aboutus2") {
        toggleToggleDouble(false)
      }
    })
    return () => {
      typeof document !== `undefined` &&
        document.removeEventListener("aos:in", ({ detail }) => {
          if (detail.id === "aboutus") {
            toggleToggleSingle(true)
          }
          if (detail.id === "aboutus2") {
            toggleToggleDouble(true)
          }
        })
      document.removeEventListener("aos:out", ({ detail }) => {
        if (detail.id === "aboutus") {
          toggleToggleSingle(false)
        }
        if (detail.id === "aboutus2") {
          toggleToggleDouble(false)
        }
      })
    }
  }, [])

  return (
    <div>
      <div id="aboutus" data-aos="zoom-in-up" className="w-full lg:flex">
        <div className="lg:w-1/2">
          <div className="lato text-2xl lg:text-4xl text-left text-gray-800">
            Benjamin Becker
          </div>
          <AnimatedLine color="#2d3748" toggle={toggleSingle} delay={200} />

          <div className="py-5 lg:pr-8 text-justify text-gray-800 text-sm lg:text-base">
            Ob für Microcontroller oder das Internet, mein Ziel ist die
            Entwicklung funktionaler und gut wartbarer Software. Ich beherrsche
            mein Handwerk und strebe nach der besten Lösung für Ihre
            Problemstellung. Ich lege großen Wert auf eine professionelle
            Durchführung Ihres Auftrags und eine offene Kommunikation zwischen
            allen Projektbeteiligten. Ob Python, C++ oder JavaScript, Englisch
            oder Deutsch, ich spreche Ihre Sprache.
          </div>
        </div>
        <div className="lg:w-1/2">
          <div className="lato text-2xl lg:text-4xl text-right text-gray-800">
            Maximilian Zietz
          </div>
          <div className="flex justify-end">
            <AnimatedLine
              color="#2d3748"
              toggle={toggleSingle}
              delay={200}
              className="text-right"
            />
          </div>
          <div className="py-5 lg:pl-8 text-justify text-gray-800 text-sm lg:text-base">
            Ich schreibe Software für Webanwendungen und Roboter. Beides vereint
            mein Bestreben nach effizienten und hochwertigen Lösungen. Ich
            spreche fließend Python und JavaScript und beherrsche moderne
            Frameworks wie Django und React. Ich finde Leidenschaft in der
            Anwendung von Bewährtem sowie der Entdeckung von Neuem. Hierbei
            folge ich den Methoden und Philosophien der modernen, agilen und
            transparenten Softwareentwicklung.
          </div>
        </div>
      </div>
      <div className="h-10"></div>
      <div id="aboutus2" data-aos="zoom-in-up" className="w-full flex">
        <div className="w-full">
          <div className="flex justify-center">
            <div className="mx-3">
              <img className="w-20" src={logo} alt="Becker&&Zietz Logo" />
            </div>
          </div>
          <div className="flex justify-center mt-3">
            <AnimatedLine
              color="#2d3748"
              toggle={toggleDouble}
              delay={200}
              className="text-right"
            />
          </div>
          <div className="py-5 text-justify text-gray-800 text-sm lg:text-base">
            Gemeinsam sind wir Becker && Zietz, zwei freiberufliche
            Softwareentwickler in Hamburg. Neben unseren Einzelkompetenzen
            bieten wir Ihnen langjährig aufgebaute Synergien in der gemeinsamen
            Projektarbeit an. Wir bearbeiten Softwareentwicklungen jeglicher Art
            in Python, C++ und Javascript, die Entwicklung von Webapplikationen
            vom Front-, bis zum Backend in Django oder React, sowie hardwarenahe
            Programmierung innerhalb von IoT Anwendungen. Neben der Entwicklung
            führen wir zudem Schulungen innerhalb der von uns eingesetzten
            Programmiersprachen und Frameworks durch.
          </div>
        </div>
      </div>
      <div className="h-32"></div>
    </div>
  )
}

export default AboutUs
