import React, { useState, useEffect } from "react"
import AnimatedLine from "../Layout/AnimatedLine"
import { Element } from "react-scroll"
import {
  FiLinkedin,
  FiMail,
  FiDownloadCloud,
  FiPhoneCall,
} from "react-icons/fi"
import { IoLogoXing } from "react-icons/io"
import { withPrefix } from "gatsby"

const Contact = ({ project }) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    typeof document !== `undefined` &&
      document.addEventListener("aos:in", ({ detail }) => {
        if (detail.id === "contact") {
          setVisible(true)
        }
      })
    document.addEventListener("aos:out", ({ detail }) => {
      if (detail.id === "contact") {
        setVisible(false)
      }
    })
    return () => {
      typeof document !== `undefined` &&
        document.removeEventListener("aos:in", ({ detail }) => {
          if (detail.id === "contact") {
            setVisible(true)
          }
        })
      document.removeEventListener("aos:out", ({ detail }) => {
        if (detail.id === "contact") {
          setVisible(false)
        }
      })
    }
  }, [])

  const getDate = () => {
    var dateObj = new Date()
    return `${dateObj.getUTCDate()}_${dateObj.getUTCMonth() +
      1}_${dateObj.getUTCFullYear()}`
  }

  const contactInformation = ({
    name,
    phone,
    email,
    slug,
    hrefLinkedIn,
    hrefXing,
  }) => {
    return (
      <div className="lg:w-1/2">
        <div className="lato text-xl lg:text-2xl mt-5 lg:mt-10 tracking-widest font-thin text-gray-800">
          {name}
        </div>
        <div className="lg:flex mt-4">
          <div className="lg:w-2/3 w-full">
            <div className="flex items-center">
              <FiPhoneCall className="text-2xl lg:text-3xl text-gray-800 mr-3 my-2" />
              <div className="text-gray-800 ">{phone}</div>
            </div>
            <div className="flex items-center">
              <FiMail className="text-2xl lg:text-3xl mr-3 text-gray-800 my-2" />
              <div className="text-gray-800 ">{email}</div>
            </div>
            <div className="flex items-center">
              <FiDownloadCloud className="text-2xl lg:text-3xl mr-3 text-gray-800 my-2" />
              <a
                rel="noopener noreferrer"
                href={withPrefix(`/${slug}.pdf`)}
                target="_blank"
                download={`${slug}_${getDate()}`}
              >
                <div className="text-gray-800 ">Mein Profil als PDF</div>
              </a>
            </div>
            <div className="flex lg:justify-center lg:block">
              <div className="flex items-center">
                <a className="mr-3" href={hrefLinkedIn} title={`LinkedIn-Profil von ${name}`}>
                  <FiLinkedin className="text-2xl lg:text-3xl text-gray-800 mr-3 my-2"></FiLinkedin>
                </a>
                <a href={hrefXing} title={`XING-Profil von ${name}`}>
                  <IoLogoXing className="text-xl lg:text-3xl text-gray-800 mr-3 my-2"></IoLogoXing>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Element name="Contact" className="element">
      <div id="contact" data-aos="zoom-in-up" className="my-10">
        <div className="lato text-2xl lg:text-4xl tracking-widest font-thin text-gray-800">
          Kontakt
        </div>
        <div className="lg:w-2/3">
          <AnimatedLine color={"#2d3748"} toggle={visible} delay={200} />
        </div>
        <div className="lg:flex w-full">
          {contactInformation({
            name: "Benjamin Becker",
            phone: "0160 8117881",
            email: "becker@beckerundzietz.de",
            slug: "Benjamin_Becker_Portfolio",
            hrefLinkedIn:
              "https://de.linkedin.com/in/benjamin-becker-97093b17b",
            hrefXing: "https://www.xing.com/profile/Benjamin_Becker43",
          })}
          {contactInformation({
            name: "Maximilian Zietz",
            phone: "0151 61832750",
            email: "zietz@beckerundzietz.de",
            slug: "Maximilian_Zietz_Portfolio",
            hrefLinkedIn: "https://de.linkedin.com/in/maximilian-zietz-19a649197",
            hrefXing: "https://www.xing.com/profile/Maximilian_Zietz3",
          })}
        </div>
      </div>
      <div className="h-32"></div>
    </Element>
  )
}

export default Contact
