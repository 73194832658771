import React from "react"
import HomeHero from "../components/Home/HomeHero"
import HomeContent from "../components/Home/HomeContent"
import Contact from "../components/Home/Contact"
import AboutUs from "../components/Home/AboutUs"
import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import Navigation from "../components/Layout/Navigation"

const IndexPage = () => (
	<Layout>
		<Navigation contact={true} active="home"/>
		<SEO title="Home" />
		<HomeHero />
		<AboutUs />
		<HomeContent />
		<Contact />
	</Layout>
)

export default IndexPage
